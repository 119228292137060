<template>
  <div>
    <air-reset />
  </div>
</template>
<script>
import AirReset from '@/components/system/Auth/Reset'
export default {
  components: {
    AirReset,
  },
}
</script>
