<template>
	<div :class="$style.auth">
		<div class="pt-5 pb-5 dF jC align-items-end mt-auto">
			<img src="@/assets/logo.svg" alt="Bildhive Logo" style="max-height:40px;" />
		</div>
		<div class="pl-5 pr-5 pt-5 pb-5 bg-white text-center" :class="$style.container">
			<div class="text-dark mb-4">
				<div class="font-size-30 ">Set Up Password</div>
				<span class="text-med-gray">Enter your new Password</span>
			</div>
			<a-form class="mb-4" @submit.prevent="reset">
				<div class="mb-3">
					<a-input-password type="password" size="large" v-model="newPass" placeholder="New Password" />
				</div>
				<a-col :span="24">
					<div v-show="newPass !== ''">
						<div v-if="getTotalPoints <= 1">
							<p style="text-align: left; margin-bottom: 0px !important;">Very weak</p>
							<div class="mb-3" style="width: 50px; border: 1px solid red;"></div>
						</div>
						<div v-else-if="getTotalPoints == 2">
							<p style="text-align: left; margin-bottom: 0px !important;">Weak</p>
							<div class="mb-3" style="width: 100px; border: 1px solid red;"></div>
						</div>
						<div v-else-if="getTotalPoints == 3">
							<p style="text-align: left; margin-bottom: 0px !important;">Medium</p>
							<div class="mb-3" style="width: 150px; border: 1px solid orange;"></div>
						</div>
						<div v-else-if="getTotalPoints == 4">
							<p style="text-align: left; margin-bottom: 0px !important;">Good</p>
							<div class="mb-3" style="width: 200px; border: 1px solid blue;"></div>
						</div>
						<div v-else>
							<p style="text-align: left; margin-bottom: 0px !important;">Strong</p>
							<div class="mb-3" style="width: 250px; border: 1px solid green;"></div>
						</div>

						<h3 class="font-size-16 text-left">Create a password that:</h3>
						<ul class="text-left"
							:style="[getPassLength ? { 'padding-inline-start': '0px !important' } : { 'padding-inline-start': '22px !important' }]">
							<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
								<a-icon v-if="getFourteenCharacter && newPass.length > 2" type="check"
									style="color: green; padding-right: 8px;" />
								<a-icon v-else-if="getFourteenCharacter == false && newPass.length > 2" type="close"
									style="color: red; padding-right: 8px;" />contains at least 14 characters
							</li>
							<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
								<a-icon v-if="getLowerAndUpper && newPass.length > 2" type="check"
									style="color: green; padding-right: 8px;" />
								<a-icon v-else-if="getLowerAndUpper == false && newPass.length > 2" type="close"
									style="color: red; padding-right: 8px;" />contains both lower (a-z) and uppercase
								letters (A-Z)
							</li>
							<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
								<a-icon v-if="getNumber && newPass.length > 2" type="check"
									style="color: green; padding-right: 8px;" />
								<a-icon v-else-if="getNumber == false && newPass.length > 2" type="close"
									style="color: red; padding-right: 8px;" />contains at least one number (0-9)
							</li>
							<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
								<a-icon v-if="getSpecialCharacter && newPass.length > 2" type="check"
									style="color: green; padding-right: 8px;" />
								<a-icon v-else-if="getSpecialCharacter == false && newPass.length > 2" type="close"
									style="color: red; padding-right: 8px;" />contains at least one special character from
								@$!%*?&.
							</li>
							<li :style="[ getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
								<a-icon v-if="validPasswordNameCheck && newPass.length > 2
									" type="check" style="color: green; padding-right: 8px" />
								<a-icon v-else-if="validPasswordNameCheck == false && newPass.length > 2
									" type="close" style="color: red; padding-right: 8px" />does not contain your name or email address
							</li>
						</ul>
					</div>
				</a-col>
				<div class="mb-3">
					<a-input-password type="password" size="large" v-model="confirmPass" placeholder="Confirm Password" />
				</div>
				<div v-if="confirmPass != ''" class="text-left mb-3">
					<div v-if="confirmPass == newPass">
						<a-icon type="check" style="color: green; padding-right: 8px;" />Password matches
					</div>
					<div v-else>
						<a-icon type="close" style="color: red; padding-right: 8px;" />Password does not match
					</div>
				</div>
				<button :disabled="loading" :loading="loading" type="submit"
					class="text-center btn btn-primary w-100  font-size-18">
					Set Up Password
				</button>
			</a-form>
		</div>
		<footer-bh />
	</div>
</template>
<script>
import { parseError, $auth } from 'bh-mod'
import FooterBh from '@/components/common/FooterTerms.vue'
import _ from "lodash";

export default {
	components: { FooterBh },
	name: 'AirRegister',
	data: function () {
		return {
			newPass: '',
			confirmPass: '',
			loading: false,
			securityCode: '',
			validPasswordNameCheck: true
		}
	},
	computed: {
		getTotalPoints() {
			var points = 0;

			if (this.newPass.length >= 4) points++;
			if ((this.newPass.match(/[a-z]/)) && (this.newPass.match(/[A-Z]/))) points++;
			if (this.newPass.match(/\d+/)) points++;
			if (this.newPass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) points++;
			if (this.newPass.length >= 14) points++;
			if (this.newPass.length >= 16) points++;

			return points
		},
		getPassLength() {
			let passLength = false;
			if (this.newPass.length > 2) {
				passLength = true
			}
			return passLength
		},
		getFourteenCharacter() {
			let fourteenCharacter = false;
			if (this.newPass.length >= 14) {
				fourteenCharacter = true
			}
			return fourteenCharacter
		},
		getLowerAndUpper() {
			let lowerAndUpper = false;
			if (this.newPass.match(/[a-z]+/) != null && this.newPass.match(/[A-Z]+/) != null) {
				lowerAndUpper = true
			}
			return lowerAndUpper
		},
		getNumber() {
			let number = false;
			if (this.newPass.match(/[0-9]+/) != null) {
				number = true
			}
			return number
		},
		getSpecialCharacter() {
			let specialCharacter = false;
			if (this.newPass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
				specialCharacter = true
			}
			return specialCharacter
		},
	},
	watch: {
		newPass() {
			this.passwordCheckDebounce();
		},
	},
	methods: {
		resetOk() {
			this.$notification["success"]({
				message: 'Successfully Reset',
				description: 'You can now login using your updated credentials.',
			});
			this.$router.push('/')
		},
		reset() {
			if (this.newPass.match(/[0-9]+/) == null) return this.$message.error('Password must contain a number')
			if (this.newPass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) == null) return this.$message.error('Password must contain a special character from @$!%*?&.')
			if (this.newPass.match(/[a-z]+/) == null) return this.$message.error('Password must contain a lowercase letter')
			if (this.newPass.match(/[A-Z]+/) == null) return this.$message.error('Password must contain an uppercase letter')
			if (!this.validPasswordNameCheck) return this.$message.error('Password must not contain your name or email address')
			if (this.newPass.length < 14) return this.$message.error('Password must be atleast 14 characters in length')
			if (this.newPass !== this.confirmPass) return this.$message.error('Confirm password does not match')

			this.loading = true;
			let self = this
			$auth.post('/auth/reset-password', {
				code: this.securityCode,
				password: this.newPass,
				passwordConfirmation: this.confirmPass,
			}).then(() => {
				self.loading = false;
				self.resetOk()
			}).catch((err) => {
				console.error(err)
				self.loading = false;
				let error = ''
				error = parseError(err, 'Something went wrong. If you are still unable to access your account please send an email to our Customer Care team and we would be happy to help you.')
				if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.length) {
					let e = err.response.data.errors.map((e) => {
						return `${e.path && e.path[0]}: ${e.message}`
					})
					error = e.join(', ')
				}
				console.error(error)
				this.$message.error(error)
			})
		},

		passwordCheckDebounce: _.debounce(async function () {
			this.validPasswordNameCheck = true
			try {
				const { data } = await $auth.post('auth/validate-password-user-info', { code: this.securityCode, password: this.newPass })
				this.validPasswordNameCheck = data.success
			} catch (err) {
				let error = parseError(err, 'Something went wrong while checking for reset password name validation. Please try again')
				console.error(error)
				this.$message.error(error)
			}
		}, 1200),
	},

	created() {
		let code = location.search.split('?code=')
		if (code.length !== 2) return this.$message.error('Something went wrong. Please try again!')

		this.securityCode = code[1]
	}
}
</script>
<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>
